// Gatsby supports TypeScript natively!
// @ts-ignore
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = (props: PageProps) => (
  <Layout>
    <SEO title="Projects" />
    <p>Random side projects that I never complete</p>
  </Layout>
)

export default Projects
